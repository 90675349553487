import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addCourse, addTopic } from "../../actions/courseAction";
import { setAlert } from "../../actions/alert";
import { commonAxios } from "../../global/CommonAxios";
import { IoReloadSharp } from "react-icons/io5"
import Alert from "../Alert";
import { useEffect } from "react";
import CourseHeader from "./CourseHeader";

const CreateTopic = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(state => state.auth)
    const course = useSelector(state => state.course)
    const [loader, setLoader] = useState(false)
    const [topic, setTopic] = useState("")
    const [limit, setLimit] = useState("")
    const [aiSuggestion, setAiSuggestion] = useState([])
    const [aiLoader, setAiLoader] = useState({
        loader: false,
        message: ''
    })
    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };

    const handleChange = (e) => {
        if (e.target.value.length < 101) {
            dispatch(addTopic(e.target.value))
            setTopic(e.target.value)
        }else{
            dispatch(setAlert("Max limit is 100 characters only", "danger"))
        }
    }

    const handleSubmit = (e) => {
        dispatch(addTopic(topic))
        e.preventDefault()
        let data = {
            courseId: course.courseId,
            projectName: course.projectName,
            topicName: topic
        }
        setLoader(true)
        commonAxios("course-update", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    history.push('/create-chapter')
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setLoader(false)
            }).catch(err => {
                setLoader(false)
                console.log(err)
            })
    }

    const fetchCourse = (id) => {
        let data = { "courseId": id }
        commonAxios("course-fetch", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    dispatch(addCourse(res.data))
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
            }).catch(err => console.log(err))
    }

    const getAiData = (aiLim) => {
        if (auth.user.is_client_account !== "1") {
            let data
            if (topic !== "") {
                data = {
                    text: topic,
                    type: "topic",
                    limit: parseInt(course.topicLimit) + 1,
                    courseId: course.courseId
                }
                if (aiLim === 0) {
                    data.limit = 0
                }
                console.log(data)
                setAiLoader({
                    ...aiLoader,
                    loader: true
                })
                commonAxios("openai-call", data, dispatch, config)
                    .then((res) => {
                        if (res.status) {
                            setAiSuggestion(res.data)
                            setLimit(auth.user.topic_limit - data.limit)
                            fetchCourse(course.courseId)
                        }
                        else {
                            dispatch(setAlert(res.msg, "danger"))
                        }
                        setAiLoader({
                            ...aiLoader,
                            loader: false,
                        })
                    }).catch((error) => {
                        setAiLoader({
                            ...aiLoader,
                            loader: false
                        })
                        console.log(error)
                    })
            }
            else {
                dispatch(setAlert("Please enter the keyword for AI recommendation", "danger"))
            }
        }

    }

    const handleUse = (str, index, id, status) => {
        dispatch(addTopic(str))
        setTopic(str)
        let data = {
            courseId: course.courseId,
            id: id,
            type: "topic",
            isSelected: status === "0" ? "1" : "0"
        }
        commonAxios("use-ai-record", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    let val = [...aiSuggestion]
                    val.forEach(curElem => { curElem.isSelected = "0" })
                    val[index].isSelected = "1"
                    setAiSuggestion(val)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        let data = {
            courseId: course.courseId,
            type: "topic",
        }
        commonAxios("course-ai-data", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    setAiSuggestion(res.data)
                }
            }).catch((err) => {
                console.log(err)
            })
    }, [])


    useEffect(() => {
        setLimit(auth.user.topic_limit - course.topicLimit)
    }, [course.topicLimit])

    return (
        <>
            <TitleBar title="Create Course" />
            <Navbar />
            <Alert />
            <section className="siteWrap">
                <div className="create-course-top">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <h2>
                                    <CourseHeader
                                        courseName={course.projectName}
                                        courseId={course.courseId}
                                        topicName={course.topicName}
                                        config={config}
                                    />
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="create-course-mid alt">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 text-center" >
                                <form className="row" onSubmit={handleSubmit}>
                                    <div className="col-md-12">
                                        <h2 className="text-center">Course  Topic  Research</h2>
                                    </div>
                                    <div className="col-md-8 mt-4">
                                        <input
                                            className="courseInp"
                                            placeholder="Enter The Keyword"
                                            required
                                            name="projectName"
                                            value={topic}
                                            maxLength={100}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col-md-4 text-md-start text-sm-center p-0">
                                        <button
                                            type="submit"
                                            className="demoLink-topic"
                                            disabled={auth.user.is_client_account === "1" ? true : false}
                                        >{loader ? <> Saving <i className="fa fa-spinner fa-spin mr-2" /> </> : "Save"}</button>
                                    </div>
                                    <div className="col-md-12 text-center mt-4">
                                        <div className="create-or-line"> <span>OR</span> </div>
                                    </div>

                                    <div className="col-md-12 text-center mt-4">
                                        <div className="courseBtn">
                                            {aiSuggestion.length === 0 ?
                                                <button
                                                    type="button"
                                                    className="demoLink"
                                                    onClick={() => getAiData(0)}
                                                >
                                                    {aiLoader.loader ?
                                                        <>
                                                            <i className="fa fa-spinner fa-spin mx-2" />
                                                            Fetching Recommendations
                                                        </> : 'AI Recommendation'
                                                    }</button>
                                                :
                                                <button
                                                    type="button"
                                                    className="demoLink"
                                                    disabled={limit === 0 ? true : false}
                                                    style={limit === 0 ? { background: "gray" } : {}}
                                                    onClick={getAiData}

                                                >
                                                    {aiLoader.loader ?
                                                        <i className="fa fa-spinner fa-spin mx-2" /> :
                                                        <>
                                                            <IoReloadSharp
                                                                style={{ fontSize: "35px", transform: " rotate(172deg)" }}
                                                            />
                                                            <a style={{
                                                                position: "relative",
                                                                top: "1px", left: "-20px", fontSize: "13px"
                                                            }}
                                                            >{limit}</a>
                                                        </>
                                                    }
                                                    <span>  {aiLoader.loader ?
                                                        <>
                                                            Loading More Recommendations
                                                        </> : 'Load More Recommendations'
                                                    }</span>

                                                </button>
                                            }
                                        </div>
                                    </div>
                                </form>

                                <div className="recomnd">
                                    <ul>
                                        {aiSuggestion.length > 0 ?
                                            aiSuggestion.map((curElem, index) => {
                                                let str = curElem.output_string.replace(/^[1-9][0-9]*\.\s|^[1-9][0-9]*\)\s/g, '')
                                                return (
                                                    <li key={index}>
                                                        <div className="recomnd-single d-flex justify-content-between align-items-center">
                                                            <p
                                                                style={{
                                                                    textAlign: "left",
                                                                    paddingRight: '23px',
                                                                    color: '#fff',
                                                                    margin: "auto 0"

                                                                }}
                                                            >{str}</p>
                                                            <button type="button" className={`${curElem.isSelected === "1" ? "demoLink-Ai-search" : "demoLink"} mt-0 ms-1`} onClick={() => handleUse(str, index, curElem.id, curElem.isSelected)} > {curElem.isSelected === "1" ? "Using" : "Use This"}</button>
                                                        </div>
                                                    </li>
                                                )
                                            })

                                            : ''}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section >

            <Footer />
        </>
    )
}

export default CreateTopic;