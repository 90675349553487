import React, { useState } from 'react';
import addReelIcon from "../../../images/add-reel.svg";
import { FiTrash2 } from "react-icons/fi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedTiles, updateSlidesData, handleDragStatus, updateVideoThumbnail, removeTtsFromSlides, addNewSlide, deleteTile } from '../../.././actions/chapterAction';
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from '../../Alert'
// import AddSlide from './AddSlide';
import { BiMicrophoneOff } from 'react-icons/bi'
import { BiMicrophone } from 'react-icons/bi'
import { setAlert } from '../../../actions/alert';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import loginGg from '../../../images/login-bg.jpg'
import { ReactComponent as TransitionIcon } from '../../../images/transition.svg'
import { commonAxios } from '../../../global/CommonAxios';



const VideoTiles = ({ slideData }) => {
console.log("course",slideData)
  const dispatch = useDispatch()
  const [button, setButton] = useState('Delete');
  const [showDelete, setShowDelete] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteTileDetails, setDeleteTileDetails] = useState({
    indexNumber: -1,
    indexActiveStatus: "0",
    e: ""
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  let config = {
    headers: {
      "Content-Type": "application/json",
      'Authorization': localStorage.token
    },
  };

  const handleSweetAlert = (indexNumber, indexActiveStatus, e) => {
    e.stopPropagation()
    setDeleteTileDetails({
      ...deleteTileDetails,
      indexNumber: indexNumber,
      indexActiveStatus: indexActiveStatus,
    })
    setShowDelete(true);
  }

  const onConfirm = () => {
    handleDeleteTile();
    setButton('Deleting...');
  }

  const onCancel = () => {
    setShowDelete(false);
  }
  const onSetRecorder = (result) => {

    if (!result.destination) {
      return;
    }
    const items = reorder(slideData, result.source.index, result.destination.index);
    dispatch(updateSlidesData(items));
    dispatch(handleDragStatus());
  }

  const onSelectTile = (index) => {
    dispatch(updateSelectedTiles(index))
  }

  const handleDeleteTile = () => {
    let data = [...slideData]
    let newIndex = deleteTileDetails.indexNumber
    if (data.length === 1) {
      swal('Oops..', 'You cannot delete last slide');
    } else {
      setTimeout(() => {
        if (deleteTileDetails.indexActiveStatus === "1") {

          if (deleteTileDetails.indexNumber === 0) {
            newIndex = 0;
          } else {
            newIndex = deleteTileDetails.indexNumber - 1;
          }
          dispatch(deleteTile(deleteTileDetails.indexNumber, newIndex))

        }
        else {
          dispatch(deleteTile(deleteTileDetails.indexNumber, false))
        }
        setShowDelete(false)
        setButton("Delete")
      }, 1000)
    }
  }

  const handleTts = (slideIndex, e) => {
    e.stopPropagation()
    dispatch(setAlert("Voiceover removed successfully", "success"))
    dispatch(removeTtsFromSlides(slideIndex))
  }

  const handleAddSlide = (id, cId) => {
    if (slideData.length < 15) {
      let data = {
        courseId: id,
        chapterId: cId
      }
      setLoader(true)
      commonAxios("slide-add", data, dispatch, config)
        .then((res) => {
          if (res.status) {
            dispatch(addNewSlide(res.data))
            dispatch(setAlert("A new slide added successfully", "success"))
          } else {
            dispatch(setAlert(res.msg, "danger"))
          }
          setLoader(false)
        }).catch((err) => {
          setLoader(false)
          console.log(err)
        })
    } else {
      dispatch(setAlert("You can have maximum 15 slides in a chapter.", "danger"))
    }
  }

  return (

    <>
      <Alert />
      <div className="editorReelwrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="editorReel-scroll">
                <DragDropContext onDragEnd={onSetRecorder}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided) => (
                      <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {
                          slideData.length > 0 ?
                            slideData.map((curElem, index) => {
                              console.log("ankit",curElem)
                              return (
                                <>

                                  <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                    {(provided) => (
                                      <li
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={index}
                                        onClick={() => onSelectTile(index)}
                                        className={curElem.isSelected === "1" ? 'selected-tile' : 'selected-tile-hover'}
                                      >
                                        <div

                                          className="editorReel-single "
                                          style={curElem.isSelected === "1" ? { border: 'none', } : {}}
                                        >
                                          {curElem.background.type !== "color" ?
                                          curElem.background.type==="video"?
                                         <video  muted>
                                         <source src={curElem.background.src ? curElem.background.src : loginGg} type="video/mp4"/>
                                      
                                         Your browser does not support the video tag.
                                       </video>:
                                            <img
                                              src={curElem.background.poster ? curElem.background.poster : loginGg}
                                              alt='tiles-poster'
                                              style={{ width: 'auto', height: '100%', cursor: 'pointer' }}
                                            />
                                            :
                                            <div
                                              src={curElem.background.poster ? curElem.background.poster : loginGg}
                                              alt='tiles-poster'
                                              style={{ width: '100%', height: '100%', cursor: 'pointer', background: `${curElem.background.poster}` }}
                                            />
                                          }

                                          <FiTrash2
                                            className="delIcon"
                                            color='#fff'
                                            style={
                                              {
                                                cursor: 'pointer',
                                                color: `${curElem.isSelected === "1" ? '#a804a8' : ''}`,
                                              }
                                            }
                                            onClick={(e) => {
                                              slideData.length === 1 ?
                                                handleDeleteTile() :
                                                handleSweetAlert(index, curElem.isSelected, e)
                                            }
                                            }
                                          />
                                          {curElem.voiceOver.enable ?
                                            <OverlayTrigger

                                              placement={'top'}
                                              overlay={
                                                <Tooltip id="tooltip-top" className='tooltipsarrow' >
                                                  TTS Active
                                                </Tooltip>
                                              }

                                            >
                                              <div>
                                                <BiMicrophone
                                                  className="voiceIcn"
                                                  style={{ cursor: 'pointer', color: `${curElem.isSelected === "1" ? '#a804a8' : ''}` }}
                                                  onClick={(e) => handleTts(index, e)}
                                                />
                                              </div>
                                            </OverlayTrigger>
                                            :
                                            <OverlayTrigger
                                              placement={'top'}
                                              overlay={
                                                <Tooltip id="tooltip-top" className='tooltipsarrow'>
                                                  TTS Inactive
                                                </Tooltip>
                                              }
                                            >
                                              <div>
                                                <BiMicrophoneOff
                                                  className="voiceIcn-off"
                                                  style={{ cursor: 'pointer', color: `${curElem.isSelected === "1" ? '#a804a8' : ''}` }}
                                                />
                                              </div>
                                            </OverlayTrigger>
                                          }
                                        </div>
                                      </li>
                                    )}
                                  </Draggable>
                                  {curElem.transition.enable ?
                                    <div className="transition-wrapper" title={curElem.transition.simpleTransition}>
                                      <svg width="22px" height="22px" viewBox="0 0 22 22" >
                                        <TransitionIcon style={{ color: '#fff' }} />
                                      </svg>
                                    </div> : ""}
                                    
                                  <div className="addReelBt" onClick={() => handleAddSlide(curElem.courseId, curElem.chapterId)}>
                                    {loader ? <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#CE88FF", fontSize: '25px' }} /> :
                                      <img src={addReelIcon} alt="" />}
                                  </div>
                                </>
                              )
                            }) : ''
                        }
                        {provided.placeholder}

                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>


                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText={button}
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="light"
                  title="Are you sure?"
                  onConfirm={(e) => onConfirm(e)}
                  onCancel={(e) => onCancel(e)}
                  focusCancelBtn
                  show={showDelete}
                >
                  Do you want to delete this Tile
                </SweetAlert>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};




export default VideoTiles;
