import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import planIcon from "../../images/plan.png";
import contentIcon from "../../images/content.png";
import chaptersIcon from "../../images/chapters.png";
import TitleBar from "../TitleBar";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { commonAxios } from "../../global/CommonAxios";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";
import {
  addChapters,
  addCourse,
  addTextType,
} from "../../actions/courseAction";
import { Modal } from "react-bootstrap";
import queryString from "query-string";
import { ReactComponent as UploadIcon } from "../../images/upload.svg";
import ModalUpload from "./ModalUpload";
import RecordAudioModal from "./RecordAudioModal";
import ChapterCards from "./ChapterCards";
import SweetAlert from "react-bootstrap-sweetalert";
import CourseHeader from "./CourseHeader";
let fileType;
const Chapters = ({ location }) => {
  const courseId = queryString.parse(location.search).courseId;
  const auth = useSelector((state) => state.auth);
  const course = useSelector((state) => state.course);
  const dispatch = useDispatch();
  const history = useHistory();
  const [chapterId, setChapterId] = useState("");
  const [chapterData, setChapterData] = useState([]);

  const [uploadData, setUploadData] = useState("");
  const [loader, setLoader] = useState(true);
  const [percent, setPercent] = useState(0);

  const [button, setButton] = useState("Overwrite");
  const [showDelete, setShowDelete] = useState(false);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  let allowedExt = ["audio/mpeg", "video/mp4"];
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
  };

  const onConfirm = () => {};

  const fetchChapters = () => {
    let data = {
      courseId: courseId,
    };
    commonAxios("chapters-fetch", data, dispatch, config)
      .then((res) => {
        if (res.status) {
          dispatch(addChapters(res.data));
          setChapterData(res.data);
        } else {
          if (auth.user.is_client_account === "1") {
            history.push("/courses");
            dispatch(setAlert(res.msg, "danger"));
          } else {
            history.push("/create-chapter");
            dispatch(addChapters([]));
            setChapterData([]);
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTopic = () => {
    let data = {
      courseId: courseId,
    };
    commonAxios("course-fetch", data, dispatch, config)
      .then((res) => {
        if (res.status) {
          if (res.data.topicName === null) {
            if (auth.user.is_client_account === "1") {
              dispatch(setAlert("No Chapter Found", "danger"));
              history.push("/courses");
            } else {
              history.push("/create-topic");
            }
            setLoader(false);
          } else fetchChapters();
          dispatch(addCourse(res.data));
        } else {
          dispatch(setAlert(res.msg, "danger"));
          history.push("/courses");
        }
      })
      .catch((err) => console.log(err));
  };

  const onChangeChapter = (e) => {
    if (e.target.value === "Edit") {
      history.push("/create-chapter");
    } else {
        {auth.user.is_client_account !== "1"? setChapterId(e.target.value):setChapterId("")}
     
    }
  };

  const handleWrite = () => {
    if (chapterId !== "") {
      dispatch(addTextType(chapterId));
      history.push(`/write-chapters?id=${chapterId}`);
    }
  };
  const handleRecording = () => {
    if (chapterId !== "") {
      handleShow3();
    }
  };

  const onInputFile = (e) => {
    fileType = e.target.files[0].type;
    config = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setPercent(percent);
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
    };
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_type", "transcribe");
    formData.append("user_id", auth.user.id);
    if (chapterId !== "") {
      if (
        e.target.files[0] !== undefined &&
        allowedExt.includes(e.target.files[0].type)
      ) {
        handleShow4();
        commonAxios("file-upload", formData, dispatch, config)
          .then((res) => {
            if (res.status) {
              setUploadData(res.data.path);
              handleClose4();
              handleShow5();
            } else {
              dispatch(setAlert(res.msg, "danger"));
            }
            setPercent(0);
          })
          .catch(() => {
            setPercent(0);
          });
      } else {
        dispatch(
          setAlert("Please select a valid file either MP3 or MP4", "danger")
        );
      }
    }
  };

  useEffect(() => {
    fetchTopic();
  }, []);

  return loader ? (
    <div className="loader-sec">
      <div className="loader"></div>
    </div>
  ) : (
    <>
      <TitleBar title="Create Course" />
      <Navbar />
      <Alert />
      <section className="siteWrap">
        <div className="create-course-top">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h2>
                  <CourseHeader
                    courseName={course.projectName}
                    courseId={course.courseId}
                    topicName={course.topicName}
                    config={config}
                  />
                  | {course.topicName}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="create-course-mid">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="course-mid-main">
                  <h2 className="text-center">Chapters</h2>
                  <select
                    className="courseInp"
                    onChange={(e) => onChangeChapter(e)}
                  >
                    <option value="">Select Chapter</option>
                    {course.chapters.length > 0
                      ? course.chapters.map((curElem) => {
                          return (
                            <option
                              value={curElem.chapterId}
                              key={curElem.chapterId}
                            >
                              {curElem.name}
                            </option>
                          );
                        })
                      : ""}
                    {auth.user.is_client_account !== "1" ? (
                      <option
                        value={"Edit"}
                        className="btn btn-success"
                        type="button"
                        style={{ fontSize: "20px" }}
                      >
                        Add / Edit Chapters
                      </option>
                    ) : (
                      ""
                    )}
                  </select>
                </div>
                <div className="course-step">
                  <ul>
                    <li>
                      <span
                        onClick={handleWrite}
                        style={chapterId === "" ? { background: "gray" } : {}}
                      >
                        <div className="d-flex align-items-center">
                          <div className="course-step-img">
                            <img src={planIcon} />
                          </div>
                          <p>Create / Write & Update </p>
                        </div>
                        <div className="course-step-icon">
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </span>
                    </li>
                    <li>
                      <span
                        style={chapterId === "" ? { background: "gray" } : {}}
                      >
                        <div className="d-flex align-items-center">
                          <div className="course-step-img">
                            <img src={contentIcon} />
                          </div>
                          <p>Upload Video/Audio</p>
                        </div>
                        <div className="course-step-icon">
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <input
                          className="absInput"
                          type="file"
                          onChange={(e) => onInputFile(e)}
                        />
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={handleRecording}
                        style={chapterId === "" ? { background: "gray" } : {}}
                      >
                        <div className="d-flex align-items-center">
                          <div className="course-step-img">
                            <img src={chaptersIcon} />
                          </div>
                          <p>Record Audio</p>
                        </div>
                        <div className="course-step-icon">
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid mb-2">
          <div className="row">
            {chapterData.length > 0
              ? chapterData.map((curElem, index) => {
                  return (
                    <div className="col-xl-3 col-md-6 col-12" key={index}>
                      <ChapterCards
                        curElem={curElem}
                        fetchChapters={fetchChapters}
                        chapterData={chapterData}
                        fileType={fileType}
                      />
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </section>

      <Footer />
      <RecordAudioModal
        show3={show3}
        handleClose3={handleClose3}
        handleClose4={handleClose4}
        handleShow5={handleShow5}
        setPercent={setPercent}
        handleShow4={handleShow4}
        setUploadData={setUploadData}
      />

      {/* Modal for video upload progress */}
      <Modal className="VideoModal small" show={show4} centered>
        <Modal.Body className="bg-white text-center">
          <div className="uploadFile-wrap">
            <div className="img-wrapper mb-1">
              <UploadIcon />
            </div>
            <h4 className="modal-title mb-2 upload_title" id="delete-warn">
              Uploading File
            </h4>
            <p className="w-100 mx-auto mb-3 para1">
              <span className="d-block sp1">
                Your file is uploading to CourseReel.
              </span>
              This may take a few moments. We will alert you when completed.
            </p>
            <div className="progress-label mb-3">{percent}%</div>
            <div className="progress mb-3">
              <div
                className="progress-bar"
                style={{ width: `${percent}%` }}
              ></div>
            </div>
            <button
              type="submit"
              className="inpBtn auto mt-4"
              onClick={handleClose4}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal Upload complete */}
      <ModalUpload
        handleClose5={handleClose5}
        show5={show5}
        uploadData={uploadData}
        chapterId={chapterId}
        fetchChapters={fetchChapters}
        fileType={fileType}
      />

      <SweetAlert
        warning
        showCancel
        confirmBtnText={button}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="secondary"
        title="Are you sure?"
        onConfirm={() => onConfirm()}
        onCancel={() => setShowDelete(false)}
        focusCancelBtn
        show={showDelete}
      >
        this action will overwrite your existing script
      </SweetAlert>
    </>
  );
};

export default Chapters;
