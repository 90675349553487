import React, { useState, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { commonAxios } from '../../../global/CommonAxios';
import Cropper from "react-easy-crop";
import getCroppedImg from '../media/CropImage';
import closeModalx from "../../../images/modal-close.png"
import { updateBg } from '../../../actions/chapterAction';

const BgImageMedia = ({ selectedSlideIndex }) => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [checkImage, setCheckImage] = useState(false);
    const [cropModel, setCropModel] = useState(false);
    const [currentImage, setCurrentImage] = useState(false);
    const [outputImage, setOutputImage] = useState(false);
    const [cropLoader, setCropLoader] = useState(false);

    /*Crop image or video*/
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };

    const [search, setSearch] = useState({
        keyword: '',
        search_type: 'image',
        page: 1
    });

    const [imageData, setImageData] = useState([]);

    const onInputChange = (e) => {
        setSearch({ ...search, keyword: e.target.value });
        setImageData([]);
        setCheckImage(false)
    }

    const closePopup = () => {
        setCropModel(false);
        setOutputImage(false);
    }

    const searchImage = (page = 1) => {
        setLoader(true);
        let data = {
            keyword: search.keyword,
            search_type: search.search_type,
            page_number: page
        }
        commonAxios("load-library-images", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    let arr = [...imageData];
                    setImageData(arr.concat(res.data));

                } else {
                    setError(`Oops, No data found for the Keyword "${search.keyword}"`);
                }
                setLoader(false);
                setSearch({ ...search, page: page });
            }).catch(err => {
                setLoader(false);
                console.log(err)
            })
    }

    const handleClick = () => {
        setError(false);
        searchImage();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setError(false);
        searchImage();
    }

    const selectImage = (image, index) => {
        setCheckImage(index);
        dispatch(updateBg(image, selectedSlideIndex, "image"))
    }

    const selectCrop = (url) => {
        setCropModel(true);
        setCurrentImage(url);
        setOutputImage(false)
    }

    const selectCropImage = (image) => {
        let jsonData = {
            url: image,
            thumbnail: image,
        }
        dispatch(updateBg(jsonData, selectedSlideIndex, "image"))
        setCropModel(false);
        setOutputImage(false);
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        setCropLoader(true);
        try {
            const croppedImage = await getCroppedImg(
                currentImage,
                croppedAreaPixels,
                rotation
            )
            downloadFiles(croppedImage);
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const downloadFiles = (imageData) => {
        let ext = currentImage.match(/\.([^\./\?]+)($|\?)/)[1];
        setCropLoader(true);
        let data = {
            data: imageData,
            format: ext
        }
        commonAxios("generate-file", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    setOutputImage(res.data);
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setCropLoader(false);
            }).catch(err => {
                console.log(err)
                setCropLoader(false);
            })
    }



    return (
        <div className="innertab">
            <form className="tabSearch" onSubmit={(e) => onFormSubmit(e)}>
                <input
                    type="text"
                    placeholder="Search"
                    onInput={(e) => onInputChange(e)}
                />
                <span
                    className="inp-icon"
                    onClick={() => handleClick()}
                ><i class="fa fa-search" aria-hidden="true"></i></span>
            </form>
            <div className="innertab-scroll" id="scrollableImage" >
                <div className="row">
                    <div className="mediaList">
                        <ul>
                            <InfiniteScroll
                                dataLength={imageData.length} //This is important field to render the next data
                                next={() => searchImage(search.page + 1)}
                                hasMore={true}
                                scrollableTarget="scrollableImage"
                            >
                                <div className="media-ul-list">
                                    {

                                        imageData.length > 0 ?
                                            imageData.map((curElem, index) => {
                                                return (

                                                    <li className='col-md-6 myImage-section cursor-pointer' key={index}>
                                                        <div className="mediaList-single myImage-respo m-1">
                                                            <img className="mediaImg" src={curElem.thumbnail} alt={`${search.keyword + " " + index}`} onClick={() => selectImage(curElem, index)} />
                                                        </div>
                                                        {checkImage === index ?
                                                            <div className="slide-active">
                                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                            </div>
                                                            : ''
                                                        }
                                                        <div className="crop-icon" onClick={() => selectCrop(curElem.url)}>
                                                            <i className="fa fa-crop"></i>
                                                        </div>
                                                    </li>


                                                )
                                            })
                                            : ''
                                    }
                                </div>

                                {
                                    loader ?
                                        <div className="col-md-12">
                                            <h4 className='text-center'>
                                                <i className="fa fa-spinner fa-spin" />
                                            </h4>
                                        </div>
                                        : ''
                                }
                                {
                                    error ?
                                        <div className="col-md-12">
                                            <h6 className='text-center'>{error}</h6>
                                        </div>
                                        : ''
                                }
                            </InfiniteScroll>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={`modal ${cropModel ? 'show-modal' : ''} `} style={{}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header crop-header">
                            <h5 className="modal-title text-center">Crop Image</h5>
                            <button onClick={(e) => setCropModel(false)} type="button" className="close img-close" data-dismiss="modal"
                                aria-label="Close">
                                {/* <span aria-hidden="true"><i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span> */}
                                <img src={closeModalx} alt="" />

                            </button>
                        </div>
                        <div className="modal-body" style={{ position: "relative", height: "50vh" }}>
                            <Cropper
                                image={currentImage}
                                crop={crop}
                                zoom={zoom}
                                aspect={16 / 9}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                                objectFit="contain"
                            />
                            {
                                croppedAreaPixels === null ?
                                    <div className="crop-icon-center" style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                    }}>
                                        <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#CE88FF", fontSize: '25px' }} />
                                    </div> : ''
                            }

                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            <button className="btn-custom mr-1" type="button" onClick={() => showCroppedImage()}>
                                {cropLoader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                {cropLoader ? 'Cropping' : 'Crop'}
                            </button>
                            <button
                                className={`${outputImage === false ? 'btn-custom-disable' : 'btn-custom'}`}
                                type="button"
                                disabled={outputImage === false ? true : false}
                                onClick={() => selectCropImage(outputImage)}
                            >
                                Use
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default BgImageMedia